import { createRouter, createWebHashHistory } from "vue-router";

import DashboardLayout from "@/views/Layout/DashboardLayout";
import AuthLayout from "@/views/Layout/AuthLayout";

// Dashboard pages
import Main from "@/views/Service/Main";

// Pages
import Question from "@/views/LiveClass/Question/List.vue";
import Attend from "@/views/LiveClass/Attend/List2.vue";
import SharePractice from "@/views/LiveClass/SharePractice/List2.vue";
import Practice from "@/views/LiveClass/Practice/List.vue";
import Library from "@/views/LiveClass/Library/List.vue";
import Thousand from "@/views/LiveClass/Thousand/List.vue";
import Weekend from "@/views/LiveClass/Weekend/List.vue";
import Course from "@/views/LiveClass/Course/List.vue";
import Lesson from "@/views/LiveClass/Lesson/List3.vue";
import Notice from "@/views/LiveClass/Notice/List.vue";
import Talk from "@/views/LiveClass/Talk/List2.vue";
import Zoom from "@/views/LiveClass/Zoom/List.vue";
import Meet from "@/views/LiveClass/Meet/List6.vue";
import MyInfo from "@/views/Service/MyInfo.vue";
import Login from "@/views/Service/Login2.vue";
import NotFound from "@/views/Template/GeneralViews/NotFoundPage.vue";
import Argon from "@/views/Template/Dashboard/Dashboard.vue";
import { Auth } from "@/api";
import Main2 from "@/views/Service/Main2";

const routes = [
  {
    path: "/",
    name: "Home",
    component: DashboardLayout,
    children: [
      {
        path: "",
        name: "모바일",
        components: { default: Main2 },
        meta: { loginRequired: true },
      },
      {
        path: "/main",
        name: "홈",
        components: { default: Main },
        meta: { loginRequired: true },
      },
      {
        path: "/notice",
        name: "공지사항",
        components: { default: Notice },
        meta: { loginRequired: true },
      },
      {
        path: "/practice",
        name: "일상에서 수행연습",
        components: { default: Practice },
        meta: { loginRequired: true },
      },
      {
        path: "/zoom",
        name: "줌 참가 안내",
        components: { default: Zoom },
        meta: { loginRequired: true },
      },
      {
        path: "/sharePractice",
        name: "나누기-쓰기",
        components: { default: SharePractice },
        meta: { loginRequired: true },
      },
      {
        path: "/attend",
        name: "출석부",
        components: { default: Attend },
        meta: { loginRequired: true },
      },
      {
        path: "/lesson",
        name: "법문듣기",
        components: { default: Lesson },
        meta: { loginRequired: true },
      },
      {
        path: "/library",
        name: "수업자료실",
        components: { default: Library },
        meta: { loginRequired: true },
      },
      {
        path: "/thousand",
        name: "천일결사 자료실",
        components: { default: Thousand },
        meta: { loginRequired: true },
      },
      {
        path: "/question",
        name: "법사님 질문있어요",
        components: { default: Question },
        meta: { loginRequired: true },
      },
      {
        path: "/meet",
        name: "도반과 온라인 수업",
        components: { default: Meet },
        meta: { loginRequired: true },
      },
      {
        path: "/talk",
        name: "법륜스님 즉문즉설",
        components: { default: Talk },
        meta: { loginRequired: true },
      },
      {
        path: "/weekend",
        name: "실천활동 자료실",
        components: { default: Weekend },
        meta: { loginRequired: true },
      },
      {
        path: "/course",
        name: "학사일정",
        components: { default: Course },
        meta: { loginRequired: true },
      },
      {
        path: "/my-info",
        name: "내정보",
        components: { default: MyInfo },
        meta: { loginRequired: true },
      },
    ],
  },
  // {
  //   path: "/main2",
  //   component: Main2,
  //   name: "",
  //   children: [
  //     {
  //       path: "",
  //       name: "모바일",
  //       components: { default: Lesson },
  //       meta: { loginRequired: true },
  //     },
  //   ],
  // },

  {
    path: "/logout",
    component: AuthLayout,
    name: "로그아웃",
    children: [
      {
        path: "",
        name: "",
        components: { default: Login },
      },
    ],
  },
  {
    path: "/argon",
    component: DashboardLayout,
    name: "argon",
    children: [
      {
        path: "",
        name: "argon",
        components: { default: Argon },
      },
    ],
  },
  {
    path: "/login",
    component: AuthLayout,
    children: [
      {
        path: "",
        name: "Login",
        components: { default: Login },
      },
      { path: "*", component: NotFound },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass: "active",
  routes,
});

router.beforeEach((to, from_, next) => {
  if (to.meta.loginRequired && !Auth.isLoggedIn) {
    sessionStorage.setItem("fullPath", to.fullPath);
    next({ name: "Login" });
  } else {
    next();
  }
});

export default router;
