<!-- 조별 주소 목록 페이지 -->
<template>
  <div>
    <base-header class="pb-6 bg-success">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Route -->
            <route-bread-crumb></route-bread-crumb>
            <!-- End Route -->
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <!--Tables-->
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <!-- Search, New Button -->
            <div class="card-header border-0">
              <h3 class="mb-4 title">도반과 온라인 수업</h3>
              <div class="row align-items-center">
                <div class="col">
                  <ul class="list-group">
                    <li class="list-group-item">
                      <b>수업 참여 에티켓</b>
                    </li>
                    <li class="list-group-item">
                      1. 진행자, 학생 모두 (000님)으로 이름을 부릅니다.
                    </li>
                    <li class="list-group-item">
                      2. 수업 시작 전 10분 전에 늦지 않게 들어옵니다.
                    </li>
                    <li class="list-group-item">
                      3. 수업 중에는 이동하지 않습니다.
                    </li>
                    <li class="list-group-item">
                      4. 안전을 위해 이동 중(운전중/대중교통 이용 시/보행 시
                      등)에는 참여하지 않습니다.
                    </li>
                    <li class="list-group-item">
                      5. 이어폰(헤드셋)을 사용하고 얼굴 화면은 꼭 켜 둡니다.
                    </li>
                    <li class="list-group-item">
                      6. 다른 사람이 말할 때 마이크를 끄고 내가 말할 때 마이크를
                      켭니다.
                    </li>
                    <li class="list-group-item">
                      7. 상대의 나누기에는 개입하거나 가르치지 않고 경청합니다.
                    </li>
                    <li class="list-group-item">
                      8. 주변에 아이들이나 반려동물이 돌아다니지 않게 합니다.
                    </li>
                    <li class="list-group-item">
                      9. 제공되는 영상 주소줄은 외부에 공유하지 않습니다.
                    </li>
                    <li class="list-group-item">
                      10. 수업 시 녹화와 녹음을 하지 않습니다.
                    </li>
                    <li class="list-group-item">
                      11. 수업 중에는 음식물을 섭취하지 않습니다.
                    </li>
                  </ul>
                </div>
              </div>
              <div class="text-center mt-4">
                <base-button
                  outline
                  type="warning"
                  v-if="vocation == 'vocation'"
                  ><span class="btn-inner--icon"
                    ><i class="ni ni-chat-round text-orange"></i
                  ></span>
                  <span class="btn-inner--text"> 휴강</span>
                </base-button>
                <base-button
                  outline
                  type="warning"
                  v-else-if="vocation == 'free'"
                  ><span class="btn-inner--icon"
                    ><i class="ni ni-chat-round text-orange"></i
                  ></span>
                  <span class="btn-inner--text"> 자율프로그램</span>
                </base-button>
                <base-button outline type="warning" icon @click="goMeet" v-else
                  ><span class="btn-inner--icon"
                    ><i class="ni ni-chat-round text-orange"></i
                  ></span>
                  <span class="btn-inner--text">수업 참여하기</span>
                </base-button>
                <br /><br />
                <table align="center">
                  <tr>
                    <td>지금 한국 시간 :</td>
                    <td align="left">{{ now2 }}</td>
                  </tr>
                  <tr>
                    <td>{{ lessonTimeName }} :</td>
                    <td>{{ lessonStart }} (한국시간기준)</td>
                  </tr>
                  <tr>
                    <td align="right">수업명 :</td>
                    <td align="left">{{ lessonName }}</td>
                  </tr>
                </table>
              </div>
            </div>
            <!-- End Search, New Button -->
            <div class="card-body text-center">
              <ul class="list-group">
                <li class="list-group-item">
                  <img :src="require('@/assets/etiquette.png')" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import * as api from "@/api";
import * as moment from "moment-timezone";
import { Auth } from "@/api";
import { ref } from "vue";

export default {
  // 컴포넌트 초기화
  components: {
    RouteBreadCrumb,
  },
  // 데이터 객체 추가
  setup() {
    const lessonStart = ref("");
    const lessonNo = ref(0);
    const url = ref("");
    const isAttend = ref(false);
    const isClassDay = ref(0);
    const verboseStudent = ref(false);
    const lessonTime = ref("");
    const lessonName = ref("");
    const now2 = ref("");
    //const lessonStart2 = ref("");
    const entrance = ref("");
    // const entrance2 = ref("");
    const lessonTimeName = ref("다음 수업 시간 ");
    const vocation = ref("");
    let diff = ref(0);

    const getDataFromApi = () => {
      const token = Auth.tokenContent;
      console.log("aaaaaaaaaaa1: " + JSON.stringify(token));
      // 하드 코딩
      //token.role = "student";

      if (token.role.includes("student")) {
        verboseStudent.value = true;
      }
      // 하드 코딩
      //token.grade="경전대학";

      moment.tz.setDefault("Asia/Seoul");
      moment.updateLocale("ko", {
        weekdaysShort: ["일", "월", "화", "수", "목", "금", "토"],
      });
      let now = "";
      // 날짜 하드 코딩
      // now = moment("2024-09-10 09:45");

      // 방학 or 수업없음에 대한 코드.
      const url4 = `api/service/class2/lesson3?classType=${token.classType}&grade=${token.grade}&semester=${token.semester}&year=${token.year}&vocation=방학`;
      api.get(url4).then((response3) => {
        if (!api.isSuccess(response3)) {
          return;
        }
        const data3 = response3.data;
        console.log("aaaaaaaaaaa2: " + JSON.stringify(data3));
        now = moment(data3.today);
        let now2 = moment(now).format("YYYY-MM-DD");
        if (data3.rows > 0) {
          for (let i = 0; i < data3.data.length; i++) {
            if (
              data3.data[i].name == "휴강" &&
              now2 == moment(data3.data[i].lessonDate).format("YYYY-MM-DD")
            ) {
              vocation.value = "vocation";
            } else if (
              data3.data[i].name == "수업없음" &&
              now2 == moment(data3.data[i].lessonDate).format("YYYY-MM-DD")
            ) {
              vocation.value = "free";
            }
          }
        }
      });

      const url3 = `api/service/class2/lessonIrregular?grade=${token.grade}&semester=${token.semester}&year=${token.year}&classType=${token.classType}`;
      api.get(url3).then((response2) => {
        if (!api.isSuccess(response2)) {
          return;
        }

        const data2 = response2.data;
        console.log("aaaaaaaaaaa3: " + JSON.stringify(data2));

        const url2 = `api/service/class2/meet?grade=${token.grade}&semester=${token.semester}&year=${token.year}&local=${token.local}&room=${token.room}&team=${token.team}&classType=${token.classType}`;
        api.get(url2).then((response) => {
          if (!api.isSuccess(response)) {
            return;
          }
          const data = response.data;
          if (data.rows > 0) {
            url.value = data.data[0].url;
            let temp = moment(now).add(-3, "hours");
            let temp2 = moment(data.data[0].entrance);

            if (token.local == "해외" && token.classType == "기본") {
              if (temp < temp2) {
                diff.value = data.data[0].entranceDiff;
              } else {
                diff.value = data.data[0].startDiff;
              }
            }
          }
          const url22 = `api/service/class2/lesson4?classType=${token.classType}&grade=${token.grade}&semester=${token.semester}&year=${token.year}&belong=${token.belong}&diff=${diff.value}`;
          api.get(url22).then((response5) => {
            if (!api.isSuccess(response5)) {
              return;
            }
            const data5 = response5.data;
            console.log("aaaaaaaaaaa4: " + JSON.stringify(data5));
            if (data5.msg === "no data") {
              lessonStart.value = "남아있는 수업이 없습니다.";
            }
            lessonNo.value = data5.data[0].lessonNo;
            lessonName.value = data5.data[0].name;
            now2.value = moment(now).format("YYYY-MM-DD dd HH:mm:ss");
            if (token.belong == "주간") {
              console.log("aa: " + JSON.stringify(data5.data[0].lessonDate));
              console.log("aa: " + diff.value);
              lessonStart.value = moment(data5.data[0].lessonDate)
                .add(diff.value, "milliseconds")
                .format("YYYY-MM-DD HH:mm");
            } else if (token.belong == "저녁") {
              lessonStart.value = moment(data5.data[0].nightLessonDate)
                .add(diff.value, "milliseconds")
                .format("YYYY-MM-DD HH:mm");
            }
            console.log("aa:" + JSON.stringify(lessonStart.value));
            let attendStart = moment(lessonStart.value).add(-20, "minutes");
            let attendEnd = moment(lessonStart.value).add(1, "minutes");
            let lateEnd = moment(lessonStart.value).add(16, "minutes");
            let lessonEnd = moment(lessonStart.value).add(2, "hours");
            if (now >= attendStart && now < attendEnd) {
              isClassDay.value = 1;
              isAttend.value = true;
            } else if (now >= attendEnd && now < lateEnd) {
              isClassDay.value = 1;
              isAttend.value = false;
            } else if (now >= lateEnd && now < lessonEnd) {
              isClassDay.value = 2;
            } else {
              isClassDay.value = 0;
            }
            if (verboseStudent.value === false) {
              isClassDay.value = 2;
            }
            // 수업일이 아닌 날에 하는 행사 주소줄로 바로 가기.
            if (data2.rows > 0) {
              if (
                moment(lessonStart.value) > moment(data2.data[0].lessonDate)
              ) {
                lessonStart.value = moment(data2.data[0].lessonDate).format(
                  "YYYY-MM-DD dd HH:mm:ss"
                );
                lessonName.value = data2.data[0].lessonName;
              }
              if (
                now > moment(data2.data[0].start) &&
                now < moment(data2.data[0].end)
              ) {
                isClassDay.value = 2;
              }
            }
            if (isClassDay.value == 1) {
              if (!isAttend.value) {
                const url3 = `api/service/class2/attend2?memberNo=${token.memberNo}`;
                api.get(url3).then((response3) => {
                  if (!api.isSuccess(response3)) {
                    return;
                  }
                  const data3 = response3.data;
                  if (
                    data3.rows > 0 &&
                    eval(
                      "data3.data[0].onlineLesson" + lessonNo.value + ".attend"
                    ) === 0
                  ) {
                    isAttend.value = true;
                  } else {
                    isAttend.value = false;
                  }
                });
              }
            }
          });
        });
      });
    };

    getDataFromApi();

    const goMeet = () => {
      const data = {};
      if (url.value && !verboseStudent.value) {
        window.open(url.value);
      } else if (isClassDay.value == 0) {
        alert("수업시간이 아닙니다...");
      } else if (isClassDay.value == -1) {
        alert("시스템 에러입니다. 진행자에게 공유해주세요!");
      } else if (url.value && isClassDay.value === 2) {
        window.open(url.value);
      } else if (url.value && verboseStudent.value) {
        if (!isAttend.value) {
          window.open(url.value);
        } else if (isAttend.value) {
          if (isClassDay.value === 1) {
            data.lessonType = "online";
            data.lessonStart = lessonStart.value;
            api
              .put(`api/service/class2/attend2/${lessonNo.value}`, { data })
              .then((response) => {
                if (!api.isSuccess(response)) {
                  return;
                }
                const data2 = response.data;
                if (data2.msg === "ok") {
                  isAttend.value = false;
                  window.open(url.value);
                } else {
                  alert("수업시간이 아닙니다..");
                }
              });
          }
        }
      }
    };
    return {
      lessonStart,
      lessonNo,
      url,
      isAttend,
      isClassDay,
      verboseStudent,
      lessonTime,
      now2,
      entrance,
      lessonTimeName,
      lessonName,
      goMeet,
      vocation,
    };
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
img {
  width: 100%;
}
</style>
