<!-- 공지 목록 페이지 -->
<template>
  <div>
    <base-header class="pb-6 bg-success">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Route -->
            <route-bread-crumb></route-bread-crumb>
            <!-- End Route -->
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0 title">공지사항</h3>
                </div>
              </div>
            </div>
            <div class="card-body">
              <ul class="list-group list-group-flush list my--3">
                <li
                  v-for="row in tableData"
                  :key="row.id"
                  class="list-group px-0"
                >
                  <td class="list-group-item px-2 afterloading">
                    <div class="felx">
                      <h3 class="text-left" v-html="row.title"></h3>
                      <p class="text-right" v-html="row.createdTime"></p>
                    </div>
                    <hr />
                    <p class="text-left" v-html="row.post"></p>
                    <!-- <hr />
                    <p class="text-right" v-html="row.createdTime"></p> -->
                  </td>
                  <br />
                </li>
              </ul>
              <!-- Pagination -->
              <div class="card-footer py-4 d-flex justify-content-center">
                <common-pagination
                  @page-change="getDataFromApi"
                  :rows-per-page="perPage"
                  :total="total"
                />
              </div>
              <!-- End Pagination -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import * as api from "@/api";
import moment from "moment";
import { Auth } from "@/api";
import { ref } from "vue";

export default {
  // 컴포넌트 초기화
  components: {
    RouteBreadCrumb,
  },
  // 데이터 객체 추가
  setup() {
    const tableData = ref([]); // 레코드셋
    const perPage = ref(20); // 페이지당 레코드 수
    const total = ref(0); // 총 레코드 수

    // api 호출
    const getDataFromApi = (page = 1) => {
      const token = Auth.tokenContent;
      const url = `api/service/class2/noticeStudent?page=${page}&grade=${token.grade}&semester=${token.semester}&year=${token.year}&classType=${token.classType}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data;
        if (data.rows > 0) {
          for (const row of d) {
            row.createdTime = moment(row.createdTime).format("YYYY-MM-DD");
          }
        }
        tableData.value = d;
        total.value = data.total;
      });
    };
    getDataFromApi(1);
    return {
      tableData, // 레코드셋
      perPage, // 페이지당 레코드 수
      total, // 총 레코드 수
    };
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}

hr {
  margin-top: 10px;
  margin-bottom: 10px;
}
.felx {
  display: flex;
  justify-content: space-between;
}
</style>

<style>
.afterloading p {
  margin-bottom: 0.2rem;
}
</style>
