<template>
  <div class="wrapper">
    <side-bar>
      <template v-slot:links>
        <sidebar-item
          :link="{
            name: '홈',
            icon: 'ni ni-shop text-primary',
            path: '/main',
          }"
        />
        <sidebar-item
          v-if="isLesson"
          :link="{
            name: '법문 듣기',
            icon: 'ni ni-tv-2 text-green',
            path: '/lesson',
          }"
        />
        <sidebar-item
          :link="{
            name: '도반과 온라인 수업',
            icon: 'ni ni-chat-round text-teal',
            path: '/meet',
          }"
        />
        <!-- <sidebar-item
          :link="{
            name: '줌 입장하기',
            icon: 'ni ni-bell-55 text-green',
            path: '/zoom',
          }"
        /> -->
        <sidebar-item
          :link="{
            name: '일상에서 수행연습',
            icon: 'ni ni-palette text-red',
            path: '/practice',
          }"
        />

        <sidebar-item
          :link="{
            name: '수업자료실',
            icon: 'ni ni-books text-yellow',
            path: '/library',
          }"
        />
        <sidebar-item
          :link="{
            name: '실천활동 자료실',
            icon: 'ni ni-world text-green',
            path: '/weekend',
          }"
        />
        <sidebar-item
          :link="{
            name: '천일결사 자료실',
            icon: 'ni ni-books text-primary',
            path: '/thousand',
          }"
        />
        <sidebar-item
          :link="{
            name: '공지사항',
            icon: 'ni ni-notification-70',
            path: '/notice',
          }"
        />
        <sidebar-item
          :link="{
            name: '법사님 질문있어요',
            icon: 'ni ni-send text-primary',
            path: '/question',
          }"
        />
        <!-- 즉문즉설 이동수업지침에 따라 수기 진행으로 메뉴 하이드시킴 -->
        <!-- <sidebar-item
          :link="{
            name: '즉문즉설',
            icon: 'ni ni-tv-2 text-orange',
            path: '/talk',
          }"
        /> -->
        <sidebar-item
          :link="{
            name: '출석부',
            icon: 'ni ni-hat-3',
            path: '/attend',
          }"
        />
        <sidebar-item
          :link="{
            name: '학사일정',
            icon: 'ni ni-hat-3 text-pink',
            path: '/course',
          }"
        />
        <sidebar-item
          :link="{
            name: '내정보',
            icon: 'ni ni-single-02 text-dark',
            path: '/my-info',
          }"
        />
      </template>
      <template v-slot:links-after>
        <hr class="my-3" />
        <ul class="navbar-nav mb-md-3">
          <li class="nav-item">
            <a class="nav-link" href="#" @click.prevent="logout">
              <i class="ni ni-user-run text-gray"></i>
              <span class="nav-link-text">로그아웃</span>
            </a>
          </li>
        </ul>
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
      <div @click="$sidebar.displaySidebar(false)">
        <router-view></router-view>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { Auth } from "@/api";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
  },
  data() {
    return {
      verboseStudent: false,
      verboseBuldae: false,
      isLesson: false,
    };
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
    logout() {
      Auth.logout(this.$router);
    },
  },
  mounted() {
    this.initScrollbar();
  },
  created() {
    const token = Auth.tokenContent;
    this.verboseStudent = token.role.includes("student");
    this.verboseBuldae = token.grade.includes("불교대학");
    const date1 = new Date("2025-5-28");
    let today = new Date();
    if (token.classType == "기본" || date1 < today) {
      this.isLesson = true;
    } else {
      this.isLesson = false;
    }
  },
};
</script>
