<!-- 일상에서 수행연습 목록 페이지 -->
<template>
  <div>
    <base-header class="pb-6 bg-success">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Route -->
            <route-bread-crumb></route-bread-crumb>
            <!-- End Route -->
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0 title">일상에서 수행연습</h3>
                </div>
              </div>
            </div>
            <div class="card-body">
              <table align="center" width="100%">
                <ul class="list-group list-group-flush list my--3">
                  <li
                    v-for="row in tableData"
                    :key="row.start"
                    order="ascending"
                    class="list-group px-0"
                  >
                    <td class="list-group-item px-2">
                      <p class="text-left">
                        {{ row.start1 }}월 {{ row.start2 }}일 ~ {{ row.end1 }}월
                        {{ row.end2 }}일
                      </p>
                      <hr />
                      <p class="text-left" v-html="row.post"></p>
                      <div class="text-center mt-4">
                        <base-button type="secondary" @click="goShare(row)">
                          나누기 보기
                        </base-button>
                        <br />
                        <br />
                      </div>
                    </td>
                    <br />
                  </li>
                </ul>
              </table>
              <!-- Pagination -->
              <div class="card-footer py-4 d-flex justify-content-center">
                <common-pagination
                  @page-change="getDataFromApi"
                  :rows-per-page="perPage"
                  :total="total"
                />
              </div>
              <!-- End Pagination -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import * as api from "@/api";
import moment from "moment";
import { Auth } from "@/api";
import { ref } from "vue";
import { useRouter } from "vue-router";

export default {
  // 컴포넌트 초기화
  components: {
    RouteBreadCrumb,
  },
  // 데이터 객체 추가
  setup() {
    const tableData = ref([]); // 레코드셋
    const perPage = ref(20); // 페이지당 레코드 수
    const total = ref(0); // 총 레코드 수
    const router = useRouter();

    // api 호출
    const getDataFromApi = (page = 1) => {
      let a = window.innerWidth;
      let b = window.innerHeight;
      if (a < b) {
        router.push({
          name: "모바일",
        });
      }
      const token = Auth.tokenContent;
      const url = `api/service/class2/practiceRetro?page=${page}&classType=${token.classType}&grade=${token.grade}&semester=${token.semester}&year=${token.year}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data;
        if (data.rows > 0) {
          for (const row of d) {
            row.start1 = moment(row.start).format("M");
            row.start2 = moment(row.start).format("D");
            row.end1 = moment(row.end).format("M");
            row.end2 = moment(row.end).format("D");
            row.post = getContent(row.post);
          }
        }
        tableData.value = d;
        total.value = data.total;
      });
    };
    getDataFromApi(1);

    const getContent = (content) => {
      return content.split("\n").join("<br>");
    };

    const goShare = (row) => {
      router.push({
        name: "나누기-쓰기",
        query: {
          suid: row.id,
        },
      });
    };
    return {
      tableData, // 레코드셋
      perPage, // 페이지당 레코드 수
      total, // 총 레코드 수
      goShare,
    };
  },
};
</script>
<style>
.breadcrumb-item {
  color: white;
}
.title {
  color: #ea6010;
  font-family: Noto Sans;
}
.text {
  color: #000000;
  font-family: Noto Sans;
  padding-bottom: 10px;
}
.text-day {
  color: #413e3e;
  font-family: Noto Sans;
  font-size: 13px;
  padding-bottom: 10px;
  text-align: right;
}
.align-right {
  text-align: right;
}
body {
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
